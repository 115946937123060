var __templateData = function anonymous(locals) {
var buf = [];
buf.push("<!DOCTYPE html><html lang=\"en\"><head><meta charset=\"utf-8\"><meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, minimal-ui\"><title>obai calculator</title><link rel=\"stylesheet\" href=\"/css/app.css\"><script defer src=\"/js/vendor.js\"></script><script defer src=\"/js/app.js\" onload=\"require(&quot;initialize&quot;)\"></script></head><body></body></html>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}