module.exports.obai1 =
	sugar: 77
	guarana: 12
	citricAcid: 5.3
	sodiumbicarbonate: 2.6
	aroma: 1.7
	caffeine: 1.4

module.exports.obai2 =
	sugar: 77
	guarana: 11.2
	citricAcid: 5.3
	sodiumbicarbonate: 2.6
	aroma: 2.5
	caffeine: 1.4

module.exports.obai3 =
	sugar: 81.84
	guarana: 6.19
	citricAcid: 5.29
	sodiumbicarbonate: 2.59
	aroma: 2.5
	caffeine: 1.6
